/*-==========FLIP AREA =========-*/
.flip-box-item {
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  -o-perspective: 1000px;
  perspective: 1000px;
  margin-bottom: 30px;
  .category-item {
    @include transform(translateZ(40px));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin-bottom: 0;
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    &:after {
      display: none;
    }
    .cat__title {
      font-size: $display-12;
    }
    .theme-btn {
      opacity: 1;
      visibility: visible;
    }
    .category-content {
      position: inherit;
      padding: 0;
      top: auto;
      left: auto;
      display: block;
      width: auto;
      height: auto;
      &:hover {
        padding-top: 0;
      }
    }
    &:hover {
     .cat__meta {
       opacity: 1;
       visibility: visible;
       margin-top: 4px;
     }
    }
  }
  .front,
  .back {
    position: relative;
    background-color: $white;
    width: 100%;
    height: 100%;
    @include transition(1s cubic-bezier(.5,1.3,.5,1.3));
    @include box-shadow(0 0 40px rgba(82, 85, 90, 0.05));
    @include border-radius(4px);
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    img {
      width: 100%;
      @include border-radius(4px);
    }
    &:after {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background-color: $theme-color;
      opacity: .80;
      content: "";
      @include border-radius(4px);
      @include transition(0.3s);
    }
  }
  .front {
    z-index: 10;
  }
  .back {
    background-color: $theme-color;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;
    @include transform(rotateY(-180deg));
    .category-item {
      .info-body {
        margin-top: 0;
        .info__title {
          color: $white;
        }
        .info__text {
          margin-bottom: 30px;
          color: rgba(255, 255, 255, 0.7);
        }
        .theme-btn {
          background-color: $white;
          color: $theme-color;
          &:hover {
            background-color: $theme-color-2;
            color: $white;
          }
        }
      }
    }
  }
  &:hover {
    .back {
      @include transform(rotateY(0deg));
      z-index: 3;
    }
    .front {
      @include transform(rotateY(180deg));
      z-index: -1;
    }
  }
}

/*-=========== flip-box-item 2 ==========-*/
.flip-box-item-2 {
  .info-box-item {
    @include transform(translateZ(40px));
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    @media #{$device-992} {
      padding-top: 30px;
    }
    @media #{$tab_device} {
      padding-top: 35px;
    }
    @media #{$large_mobile} {
      padding-top: 120px;
    }
    @media #{$small_mobile_three} {
      padding-top: 60px;
      padding-right: 20px;
      padding-left: 20px;
    }
    .info-body {
      margin-top: 0;
      .info__title {
        color: $white;
        @media #{$tab_device} {
          margin-bottom: 10px;
        }
        @media #{$large_mobile} {
          margin-bottom: 10px;
        }
        @media #{$small_mobile} {
          margin-bottom: 10px;
        }
      }
      .info__text {
        color: $white;
        @media #{$small_mobile_three} {
          display: none;
        }
      }
    }
  }
  .front,
  .back {
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -o-transform-style: preserve-3d;
    transform-style: preserve-3d;
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      @include border-radius(4px);
    }
    &:after {
      position: absolute;
      content: '';
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(46, 61, 98, 0.8);
      @include border-radius(4px);
    }
  }
  .back {
    @include transform(rotateX(-180deg));
  }
  &:hover {
    .back {
      @include transform(rotateX(0deg));
    }
    .front {
      @include transform(rotateX(180deg));
    }
  }
}