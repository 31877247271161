/*===================================
    testimonial-carousel
=====================================*/
.testimonial-carousel {
  .owl-dots {
    text-align: center;
    margin-top: 60px;
    div {
      width: 14px;
      height: 14px;
      line-height: 14px;
      background-color: rgba(127, 136, 151, 0.4);
      @include border-radius(50%);
      display: inline-block;
      margin: 0 2px;
      position: relative;
      @include transition(0.3s);
      @include transform(scale(0.7));
      &.active,
      &:hover {
        background-color: $theme-color-2;
        @include transform(scale(1));
      }
    }
  }
}

/*====== testimonial-item ========*/
.testimonial-item {
  position: relative;
  padding: 40px 48px 40px 48px;
  @include border-radius(4px);
  background-color: $white;
  @include box-shadow(0 0 40px rgba(0,0,0,.01));
  @include transition(0.3s);
  border: 1px solid rgba(127, 136, 151, 0.2);
  @media #{$laptop_m_four} {
    padding: 20px;
  }
  .testimonial__desc {
    margin-top: 20px;
    position: relative;
    &:after {
      position: absolute;
      content: "”";
      font-size: 100px;
      line-height: 0;
      bottom: -25px;
      right: -20px;
      color: $theme-color-4;
      font-weight: $font-weight-bold;
      @include transition(0.3s);
      opacity: 0.1;
    }
  }
  .testimonial__desc-desc {
    line-height: 30px;
    @include transition(0.3s);
  }
  .testimonial-header {
    display: -webkit-flex;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(127, 136, 151, 0.2);
    img {
      width: 54px;
      height: 54px;
      @include border-radius(50%);
    }
  }
  .testimonial__name {
    position: relative;
    padding-left: 14px;
  }
  .testimonial__name-title {
    font-size: $display-12;
    font-weight: $font-weight-semi-bold;
    @include transition(0.3s);
    margin-bottom: 2px;
  }
  .testimonial__name-meta {
    font-size: $default-font-2;
    text-transform: capitalize;
    @include transition(0.3s);
  }
  .review-stars {
    margin-left: 6px;
    li {
      margin-left: -2px;
    }
  }
  &:hover {
    background-color: $theme-color-2;
    border-color: $theme-color-2;
    .testimonial__desc-desc,
    .testimonial__name-title,
    .testimonial__name-meta {
      color: $white;
    }
    .testimonial__name {
      border-bottom-color: rgba(255, 255, 255, 0.1);
    }
    .testimonial__desc {
      &:after {
        color: $white;
      }
    }
  }
}
/*====== testimonial-carousel-2 ========*/
.testimonial-carousel-2 {
  .owl-nav {
    div {
      position: absolute;
      top: 50%;
      @include transform(translateY(-50%));
      width: 35px;
      height: 35px;
      line-height: 34px;
      text-align: center;
      @include border-radius(50%);
      background-color: $white;
      border: 1px solid rgba(127, 136, 151, 0.2);
      @include box-shadow(0 0 40px rgba(82, 85, 90, 0.1));
      font-size: $display-13;
      @include transition(0.3s);
      @media #{$laptop_m} {
        top: 38%;
        @include transform(translateY(0));
      }
      @media #{$large_mobile_three} {
        top: auto;
        bottom: -50px;
      }
      &:hover {
        background-color: $theme-color-2;
        color: $white;
      }
      &.owl-prev {
        left: -17px;
        @media #{$large_mobile_three} {
          left: 40%;
          @include transform(translateX(-40%));
        }
      }
      &.owl-next {
        left: auto;
        right: -17px;
        @media #{$large_mobile_three} {
          right: 40%;
          @include transform(translateX(40%));
        }
      }
    }
  }
}

/*========= testimonial-item-layout-2 =============*/
.testimonial-item-layout-2 {
  .testimonial__desc {
    margin-top: 0;
    &:after {
      bottom: 0;
    }
  }
  .testimonial-header {
    border-bottom: none;
    padding-bottom: 0;
    padding-top: 30px;
  }
}

/*====== testimonial-subtitle========*/
.testimonial-subtitle {
  @media #{$tab_device} {
    padding-top: 40px;
  }
  @media #{$large_mobile} {
    padding-top: 40px;
  }
  @media #{$small_mobile} {
    padding-top: 40px;
  }
}