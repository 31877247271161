/*====================================================
    breadcrumb-area
 ====================================================*/
.breadcrumb-area {
  background-color: $theme-color-5;
  height: 300px;
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  text-align: center;
  z-index: 2;
  color: $white;
  background-image: url("../images/breadcrumb-bg.jpg");
  background-size: cover;
  background-position: center;
  @media #{$small_mobile_four} {
    height: 220px;
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $theme-color;
    opacity: 0.9;
    z-index: -1;
  }
}

.breadcrumb-content {
  position: relative;
  z-index: 3;
  .bread-img-wrap {
    margin-right: 20px;
    width: 110px;
    height: 110px;
    @include border-radius(50%);
    @media #{$large_mobile_four} {
      margin-right: 0;
      margin-bottom: 20px;
      width: 100px;
      height: 100px;
    }
    img {
      width: 100%;
      height: 100%;
      @include border-radius(50%);
    }
  }
  .section__title {
    font-size: $display-5;
    text-transform: capitalize;
    font-weight: $font-weight-bold;
    color: $white;
    @media #{$tab_device} {
      font-size: $display-5;
    }
    @media #{$large_mobile} {
      font-size: $display-5;
    }
    @media #{$small_mobile} {
      font-size: $display-6;
    }
  }
  .widget-title {
    color: $white;
    font-size: $display-12;
    line-height: 30px;
    font-weight: $font-weight-medium;
  }
  .breadcrumb__list {
    margin-top: 8px;
    li {
      display: inline-block;
      text-transform: capitalize;
      color: $theme-color-5;
      font-size: $default-font;
      font-weight: $font-weight-medium;
      position: relative;
      padding-right: 22px;
      &:last-child {
        padding-right: 0;
      }
      a {
        color: $theme-color-5;
        @include transition(0.3s);
        &:hover {
          color: $theme-color-2;
        }
      }
      &.active__list-item {
        &:after {
          position: absolute;
          content: '.';
          top: -6px;
          right: 0;
          padding: 0 6px;
          font-size: $display-12;
        }
      }
    }
  }
}

/*====================================================
    breadcrumb-detail-area
 ====================================================*/
.breadcrumb-detail-area {
  text-align: inherit;
  height: 400px;
  @media #{$tab_device_two} {
    height: 470px;
  }
  @media #{$small_mobile_one} {
    height: 550px;
  }
  @media #{$small_mobile_three} {
    height: 600px;
  }
}
.breadcrumb-content.breadcrumb-detail-content {
  padding-right: 400px;
  @media #{$tab_device} {
    padding-right: 0;
  }
  @media #{$large_mobile} {
    padding-right: 0;
  }
  @media #{$small_mobile} {
    padding-right: 0;
  }
  .section__title {
    font-size: $display-6;
  }
  .widget-title {
    font-size: $display-14;
  }
  .breadcrumb__list {
    li {
      text-transform: inherit;
      padding-right: 10px;
      padding-bottom: 3px;
      line-height: 22px;
      font-size: $default-font-2;
      &:last-child {
        padding-bottom: 0;
      }
      .la {
        color: $alter-color-2;
        margin-left: -1px;
      }
      .la-globe {
        color: $white;
        margin-left: 0;
      }
    }
  }
}

/*====================================================
    instructor-breadcrumb-area
 ====================================================*/
.instructor-breadcrumb-area {
  @media #{$large_mobile_four} {
    height: 410px;
  }
}
.instructor-bread-content {
  @media #{$large_mobile_four} {
    display: block !important;
  }
  .section-heading {
    .section__title {
      @media #{$small_mobile} {
        font-size: $display-6 !important;
      }
    }
  }
  .breadcrumb__list {
    margin-top: 15px;
    li {
      font-size: $display-12;
    }
  }
}
/*====================================================
    about-breadcrumb
 ====================================================*/
.about-breadcrumb {
  background-image: url("../images/about-breadcrumb.jpg");
  text-align: inherit;
  height: 550px;
}
.breadcrumb-content.about-breadcrumb-content {
  .section__title {
    font-size: $display-4;
    line-height: 75px;
    @media #{$large_mobile} {
      font-size: $display-5;
      line-height: 65px;
    }
    @media #{$small_mobile} {
      font-size: $display-5;
      line-height: 60px;
    }
  }
  .btn-box {
    margin-top: 48px;
    display: inline-block;
    .video-play-btn {
      display: -webkit-flex;
      display: -ms-flex;
      display: flex;
      -ms-flex-align: center;
      align-items: center;
      color: $white;
      @include transition(0.3s);
      &:hover {
        color: $theme-color-2;
      }
    }
    .watch-video-btn {
      font-size: $display-10;
      width: 70px;
      height: 70px;
      line-height: 70px;
      margin-right: 15px;
      &:hover {
        color: $theme-color-2;
        span {
          color: $theme-color-2;
        }
      }
    }
  }
}
/*====================================================
    my-courses-bread
 ====================================================*/
.my-courses-bread {
  height: auto;
  text-align: left;
  display: block;
  padding-top: 50px;
  &:before {
    opacity: 0.97;
  }
}
.my-courses-bread-content {
  padding-bottom: 25px;
  .section__title {
    font-size: 38px;
  }
}
.my-courses-tab {
  .section-tab-2 {
    .nav-tabs {
      border-bottom: none;
      li {
        margin-bottom: 0;
        @media #{$small_mobile_three} {
          display: inline-block;
        }
        a {
          color: $white;
          padding-bottom: 17px;
          &:after {
            bottom: 2px;
          }
          &.active {
            color: $theme-color-2;
          }
        }
      }
    }
  }
}






