/*=============== cart-area ===============*/
.cart-head {
  .cart__title {
    font-size: $default-font;
    text-transform: capitalize;
    color: $theme-color;
    font-weight: $font-weight-semi-bold;
  }
}
.cart-body {
  img {
    width: 100px;
    @include border-radius(4px);
  }
  .cart-product-desc {
    img {
      margin-right: 20px;
      border: 1px solid $color;
      @include border-radius(2px);
    }
    .widget-title {
      @include transition(0.3s);
      display: block;
      font-size: $default-font;
      color: $theme-color;
      &:hover {
        color: $theme-color-2;
      }
    }
    p {
      color: $theme-color-4;
      line-height: 24px;
      font-size: $default-font-2;
      a {
        color: $theme-color-2;
      }
    }
  }
  .item__price {
    color: $theme-color;
    font-weight: $font-weight-bold;
    font-size: $display-14;
  }
  .input-number-group {
    .input-number-decrement {
      margin-left: 0;
    }
  }
}
.button-remove {
  background-color: rgba(127, 136, 151, 0.1);
  border: none;
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 0;
  @include border-radius(50%);
  @include transition(0.3s);
  color: $theme-color-4;
  position: absolute;
  left: 50%;
  top: 50%;
  @include transform(translate(-50%, -50%));
  outline: none;
  .fa-close {
    margin-top: -1px;
  }
  &:hover {
    background-color: $theme-color-2;
    color: $white;
  }
}

.shopping-cart-wrap {
  .table {
    thead>tr>td,
    thead>tr>th {
      border-bottom-width: 1px;
    }
    tr  {
      td {
        padding: 15px 20px;
        vertical-align: middle;
        line-height: inherit;
        position: relative;
      }
    }
  }
}

.shopping-cart-detail-item {
  background-color: $theme-color-5;
  @include border-radius(8px);
  padding: 30px 30px 33px 30px;
  margin-bottom: 30px;
}
.shopping-cart-content {
  .list-items {
    li {
      padding-left: 0;
      border-bottom: 1px solid rgba(127, 136, 151, 0.1);
      padding-bottom: 10px;
      margin-bottom: 10px;
      &:after {
        display: none;
      }
    }
  }
}
/*=============== checkout-radio-list ===============*/
.checkout-radio-list {
  .checkout-item {
    .radio-trigger {
      background-color: transparent;
      @include box-shadow(0 0 0 0);
      @include border-radius(0);
      padding: 0 0 0 30px;
      .checkmark {
        border: none;
        @include border-radius(0);
        position: inherit;
        top: 2px;
        left: -30px;
        width: auto;
        height: auto;
        &:before {
          top: 0;
          left: 0;
        }
        &:after {
          top: 5px;
          left: 5px;
        }
      }
    }
  }
}
/*====== order-details ========*/
.shopping-list {
  li {
    @media #{$small_mobile_four} {
      display: block !important;
    }
  }
  .shopping-img {
    width: 100px;
    @include border-radius(4px);
    -ms-flex-negative: 0;
    flex-shrink: 0;
    img {
      width: 100%;
      @include border-radius(4px);
    }
  }
  .shopping-link {
    margin-right: auto;
    padding-left: 20px;
    @media #{$small_mobile_four} {
      padding-left: 0;
      padding-top: 10px;
      padding-bottom: 5px;
      line-height: 20px;
    }
    a {
      color: $theme-color;
      line-height: 24px;
      @media #{$small_mobile_four} {
        line-height: 20px;
      }
    }
  }
  .shopping-price {
    span {
      display: block;
      color: $theme-color;
      font-weight: $font-weight-semi-bold;
      line-height: 20px;
    }
    .before-price {
      color: $theme-color-4;
    }
  }
}
/*====== checkout-radio-list ========*/
.secure-connection {
  i {
    color: rgba(127, 136, 151, 0.4);
  }
}
.checkout-item-list {
  .card-header,
  .card {
    @include border-radius(0);
    border: none;
    background-color: transparent;
  }
  .card:first-of-type {
    padding-top: 15px;
  }
  .card:last-of-type {
    padding-bottom: 15px;
  }
  .card-body {
    padding: 0 30px 0 30px;
    p {
      line-height: 26px;
      font-size: $default-font-2;
    }
  }
  .card {
    .card-header {
      margin-bottom: 0;
      padding: 10px 30px;
    }
  }
}
.checkout-item {
  .widget-title {
    font-size: $display-14;
    font-weight: $font-weight-semi-bold;
  }
}







