/*-=========== slider-area ==========-*/
.slider-area {
  position: relative;
  .owl-dots {
    position: relative;
    bottom: 140px;
  }
  &:hover {
    .owl-nav {
      div {
        &.owl-prev {
          left: 40px;
          @media #{$laptop_m_two} {
            left: 20px;
          }
        }
        &.owl-next {
          right: 40px;
          @media #{$laptop_m_two} {
            right: 20px;
          }
        }
      }
    }
  }
}

/*-=========== single-slide-item ==========-*/
.single-slide-item {
  position: relative;
  background-position: center center;
  background-size: cover;
  color: $white;
  padding-top: 250px;
  padding-bottom: 330px;
  z-index: 2;
  @media #{$large_mobile} {
    padding-top: 200px;
    padding-bottom: 300px;
  }
  @media #{$large_mobile_four} {
    padding-top: 140px;
    padding-bottom: 250px;
  }
  .section-heading {
    position: relative;
    z-index: 2;
    .section__title {
      font-size: $display-3;
      line-height: 80px;
      font-weight: $font-weight-bold;
      color: $white;
      margin-bottom: 20px;
      @media #{$tab_device} {
        font-size: $display-5;
        line-height: 60px;
      }
      @media #{$large_mobile} {
        font-size: $display-5;
        line-height: 60px;
      }
      @media #{$device-481} {
        font-size: $primary-font;
        line-height: 50px;
      }
    }
    .section__desc {
      font-size: $display-13;
      line-height: 34px;
      margin-bottom: 36px;
    }
  }
  .btn-box {
    position: relative;
    z-index: 2;
    @media #{$small_mobile_four} {
      text-align: center;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -ms-flex-pack: justify;
      justify-content: center;
    }
  }
  .hero-btn-right {
    .btn-text {
      @media #{$small_mobile_four} {
        margin-top: 0;
        margin-bottom: 20px;
      }
    }
  }
  &:after {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: $theme-color;
    opacity: .90;
    content: "";
    z-index: -1;
  }
  &:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../images/shape.png");
    background-size: cover;
    background-position: center;
    z-index: 1;
    opacity: 0.1;
  }
}

/*-=========== hero-slide ==========-*/
.hero-slide {
  .section-heading {
    .section__title,
    .section__desc {
      @include transform(translateY(70px));
      opacity: 0;
      visibility: hidden;
    }
  }
  .btn-box {
    opacity: 0;
    visibility: hidden;
    @include transform(translateY(60px));
  }
  .owl-item.active {
    .section-heading {
      .section__title,
      .section__desc{
        opacity: 1;
        visibility: visible;
        @include transform(translateY(0));
      }
      .section__title {
        @include transition(700ms ease);
        -webkit-transition-delay: 300ms;
        -moz-transition-delay: 300ms;
        -ms-transition-delay: 300ms;
        -o-transition-delay: 300ms;
        transition-delay: 300ms;
      }
      .section__desc {
        @include transition(700ms ease);
        -webkit-transition-delay: 700ms;
        -moz-transition-delay: 700ms;
        -ms-transition-delay: 700ms;
        -o-transition-delay: 700ms;
        transition-delay: 700ms;
      }
    }
    .btn-box {
      opacity: 1;
      visibility: visible;
      @include transform(translateY(0));
      @include transition(700ms ease);
      -webkit-transition-delay: 1100ms;
      -moz-transition-delay: 1100ms;
      -ms-transition-delay: 1100ms;
      -o-transition-delay: 1100ms;
      transition-delay: 1100ms;
    }
  }
}

.slide-bg1 {
  background-image: url("../images/slider-img1.jpg");
}
.slide-bg2 {
  background-image: url("../images/slider-img2.jpg");
}
.slide-bg3 {
  background-image: url("../images/slider-img3.jpg");
}
.slide-bg4 {
  background-image: url("../images/slider-img4.jpg");
}
.slide-bg5 {
  background-image: url("../images/slider-img5.jpg");
}

/*-=========== single-slide-item-2 ===========-*/
.single-slide-item-2 {
  padding-top: 140px;
  padding-bottom: 0;
  @media #{$small_mobile_three} {
    padding-top: 150px;
  }
  .section-heading {
    @media #{$large_mobile} {
      text-align: center;
    }
    @media #{$small_mobile} {
      text-align: center;
    }
    .section__title {
      @media #{$device-481} {
        font-size: $primary-font;
        line-height: 50px;
      }
    }
  }
  &:before {
    display: none;
  }
  &:after {
    opacity: 0.7;
  }
}

/*-=========== single-slide-item-3 ===========-*/
.single-slide-item-3 {
  padding-top: 140px;
  padding-bottom: 150px;
  @media #{$small_mobile_one} {
    padding-top: 120px;
    padding-bottom: 130px;
  }
  .section-heading {
    .section__title {
      font-size: $display-5;
      line-height: 60px;
    }
    .section__desc {
      @media #{$small_mobile_one} {
        font-size: $default-font;
        line-height: 28px !important;
      }
    }
  }
  &:before {
    display: none;
  }
  &:after {
    opacity: 0.9;
  }
}

/*==== hero-category ======*/
.hero-category {
  @media #{$tab_device} {
    margin-top: 50px !important;
  }
  @media #{$large_mobile} {
    margin-top: 50px !important;
  }
  @media #{$small_mobile} {
    margin-top: 50px !important;
  }
  .row {
    margin-right: -5px;
    margin-left: -5px;
  }
  .col-lg-4 {
    padding-right: 5px;
    padding-left: 5px;
  }
}
/*==== hero-search-form ======*/
.hero-search-form {
  .contact-form-action {
    padding-right: 40px;
    @media #{$large_mobile} {
      padding-right: 0;
    }
    @media #{$small_mobile} {
      padding-right: 0;
    }
    .section__meta {
      text-transform: inherit;
      background-color: $white;
      color: $theme-color;
      padding-top: 0;
      padding-bottom: 0;
      margin-bottom: 10px;
      font-size: $default-font-3;
      font-weight: $font-weight-regular;
    }
    .form-control {
      padding-left: 20px;
      border: none;
    }
  }
}

.our-post-content {
  background: #429b85;
  background: -moz-linear-gradient(-45deg,  #429b85 0%, #5f7b8c 100%);
  background: -webkit-linear-gradient(-45deg,  #429b85 0%,#5f7b8c 100%);
  background: linear-gradient(135deg,  #429b85 0%,#5f7b8c 100%);
  margin-top: 150px;
  padding: 30px 0;
  position: relative;
  overflow: hidden;
  @media #{$tab_device} {
    padding-bottom: 0;
  }
  @media #{$large_mobile} {
    padding-bottom: 0;
  }
  @media #{$small_mobile} {
    padding-bottom: 0;
  }
  .how-we-work-wrap {
    .col-lg-4 {
      @media #{$tab_device} {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
  }
  .hw-circle {
    width: 50px;
    height: 50px;
    @include border-radius(50%);
    position: absolute;
    border: 8px solid $white;
    opacity: 0.1;
    &:nth-child(1) {
      top: -20px;
      left: 30px;
    }
    &:nth-child(2) {
      top: 73%;
      left: 50%;
    }
    &:nth-child(3) {
      top: 5px;
      right: 10%;
    }
  }
}

.our-post-item {
  display: -webkit-flex;
  display: -ms-flex;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  @media #{$laptop_m_four} {
    display: block;
    text-align: center;
  }
  @media #{$tab_device} {
    margin-bottom: 30px;
  }
  @media #{$large_mobile} {
    margin-bottom: 30px;
  }
  @media #{$small_mobile} {
    margin-bottom: 30px;
  }
  .icon-element {
    font-size: $display-7;
    width: 55px;
    height: 55px;
    line-height: 55px;
    color: $white;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .widget-title {
    color: $white;
    font-size: $display-12;
    font-weight: $font-weight-semi-bold;
  }
  .our__text {
    margin-left: 14px;
    @media #{$laptop_m_four} {
      margin-left: 0;
      margin-top: 20px;
    }
    p {
      font-size: $default-font-2;
    }
  }
  &:hover {
    .icon-element {
      background-color: $white;
      color: $theme-color-2;
    }
  }
}